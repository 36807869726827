import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Typography, Box, List, ListItem, ListItemText, Divider } from '@mui/material';
import Navbar from '../Navbar';
import SuggestedReads from '../SuggestedReads';
import { blogPosts } from '../BlogPage';

function HowToApplyToGraduateStudies() {
  const sections = [
    {
      title: "1. Research Programs and Requirements",
      content: "The first step in both academic and professional degree applications is thorough research. However, there are important differences to note:",
      listItems: [
        "For academic degrees (Master's, PhD): In addition to program prerequisites, research opportunities and potential supervisors are crucial. Your success in a thesis-based program often depends on finding the right supervisor and a strong research environment. Most PhD programs and thesis-based master's programs expect applicants to have identified faculty members whose research aligns with their interests before applying.",
        "For professional degrees (MBA, JD, MD): These programs often focus on practical skills and training. Here, the curriculum, career outcomes, and reputation of the program are key factors. While you may not need to contact faculty directly, understanding the specific requirements and structure of the program is still essential."
      ],
    },
    {
      title: "2. Standardized Tests and Application Materials",
      content: "Both academic and professional programs may require standardized tests and other materials, but the specifics can vary.",
      listItems: [
        "Academic programs: Tests like the GRE may be required for admission to master's and PhD programs, but not all universities demand them. Ensure your test scores meet the program's standards, if applicable. You will also need to prepare materials such as transcripts, a personal statement, and research proposals.",
        "Professional programs: Tests such as the GMAT, LSAT, or MCAT are often mandatory for admission. In these cases, test preparation is crucial, as scores play a significant role in admission decisions. You'll also submit similar materials like personal statements, but research proposals aren't typically required."
      ],
    },
    {
      title: "3. Contacting Potential Supervisors for Thesis-Based Academic Programs",
      content: "This is one of the most crucial steps for applying to thesis-based academic programs and should be your priority. Unlike professional programs, in which you focus primarily on meeting admissions requirements, academic applicants—especially those seeking a master's or PhD—must establish a relationship with potential supervisors early in the process.",
      listItems: [
        "Why contact a supervisor? In academic programs, your supervisor plays a central role in guiding your research. Many programs won't even consider an application if a faculty member hasn't agreed to supervise you. This is why reaching out to potential advisors is essential before submitting your application.",
        "Research their work: Make sure your research interests align with the faculty member's expertise. Read their papers, understand their ongoing projects, and make sure your proposed research aligns with their current work.",
        "Send a concise email: Introduce yourself, express your interest in their work, and briefly explain your research goals. Attach your resume or CV, and ask if they would be open to discussing potential supervision.",
        "Prepare for follow-up: If a faculty member shows interest, you may need to have a more in-depth conversation or interview to discuss your research ideas and how they could support you."
      ],
    },
    {
      title: "4. Request Letters of Recommendation",
      content: "Both academic and professional programs require strong letters of recommendation, but the focus may differ:",
      listItems: [
        "Academic programs: Choose recommenders who are familiar with your research capabilities, academic achievements, and critical thinking skills—professors or research advisors are ideal.",
        "Professional programs: Recommenders can be supervisors, colleagues, or professors who know your professional skills and leadership potential. Their letters should emphasize your ability to succeed in a specific profession or industry."
      ],
    },
    {
      title: "5. Write a Compelling Personal Statement or Research Proposal",
      content: "While both types of programs require personal statements, academic applications often ask for a more research-focused proposal:",
      listItems: [
        "Academic programs: Your personal statement should highlight your research interests, academic background, and why the program is a good fit. If required, a research proposal is critical—this is where you outline your intended research topic and show how it aligns with the faculty's expertise.",
        "Professional programs: Here, focus on your career aspirations, leadership potential, and how the program will help you achieve your goals."
      ],
    },
    {
      title: "6. Apply for Funding",
      content: "Both academic and professional programs can be expensive, so researching financial aid is important:",
      listItems: [
        "Academic programs: Many research-based master's and PhD programs offer funding through teaching or research assistantships, fellowships, or scholarships. Make sure you understand the financial aid options available and whether they're tied to your supervisor or department.",
        "Professional programs: Scholarships and loans are more common, with fewer assistantship opportunities. However, some schools offer merit-based scholarships or grants for professional programs."
      ],
    },
    {
      title: "7. Submit Your Application and Follow Up",
      content: "Once you've completed all the necessary steps, submit your application and follow up on any pending documents:",
      listItems: [
        "Academic programs: Ensure that your supervisor is aware of your application submission and that your research proposal reflects the discussions you've had. Double-check that letters of recommendation, transcripts, and other materials are submitted on time.",
        "Professional programs: After submitting your application, make sure that all required documents have been received by the admissions office. If necessary, reach out to confirm the status of your application."
      ],
    }
  ];

  const images = [
    "/images/blog4-1.png",
    "/images/blog4-2.png",
    "/images/blog4-3.png",
    "/images/blog4-4.png",
  ];

  return (
    <Box>
      <Helmet>
        <title>How to Apply to Graduate Studies: Academic vs. Professional Degrees | GradFinder</title>
        <meta name="description" content="A comprehensive guide to navigating the application process for both academic and professional graduate programs. Learn how to apply to graduate school effectively." />
        <meta name="keywords" content="graduate school application process, academic vs professional program applications, contacting potential supervisors, application requirements, research proposals" />
      </Helmet>
      <Navbar isScrolled={true} />
      <Container maxWidth="md" sx={{ mt: 12, mb: 4 }}>
        <Typography variant="h1" component="h1" gutterBottom align="center" sx={{ mb: 4, fontWeight: 'bold' }}>
          How to Apply to Graduate Studies: Academic vs. Professional Degrees
        </Typography>
        <Typography variant="h5" paragraph align="center" sx={{ mb: 6, fontStyle: 'italic', color: 'text.secondary' }}>
          A comprehensive guide to navigating the application process
        </Typography>
        
        <Typography variant="body1" paragraph>
          Once you've decided to pursue graduate studies and know where you want to go, the application process becomes the next major step. The path to applying varies depending on whether you're aiming for an academic degree (such as a master's or PhD) or a professional degree (like an MBA, JD, or MD). Both routes have their own requirements and timelines, but they share common elements as well. Here's a guide to help you through the process, with an emphasis on academic degrees and the importance of contacting potential supervisors.
        </Typography>

        <ImageBox src={images[0]} alt="Graduate studies application overview" />

        {sections.map((section, index) => (
          <React.Fragment key={index}>
            <Divider sx={{ my: 4 }} />
            <Typography variant="h3" gutterBottom sx={{ fontWeight: 'bold' }}>
              {section.title}
            </Typography>
            <Typography variant="body1" paragraph>
              {section.content}
            </Typography>
            <List>
              {section.listItems.map((item, itemIndex) => (
                <ListItem key={itemIndex}>
                  <ListItemText secondary={item} />
                </ListItem>
              ))}
            </List>
            {(index === 2 || index === 3 || index === 6) && 
              <ImageBox 
                src={images[index === 2 ? 1 : index === 3 ? 2 : 3]} 
                alt={`Illustration for ${section.title}`} 
              />
            }
          </React.Fragment>
        ))}

        <Divider sx={{ my: 4 }} />

        <Typography variant="h2" gutterBottom sx={{ mt: 6, mb: 4, fontWeight: 'bold' }}>
          Final Thought: Contacting a Supervisor is Key for Academic Applications
        </Typography>

        <Typography variant="body1" paragraph>
          For those applying to thesis-based academic programs, the most critical takeaway is to reach out to potential supervisors before submitting your application. Building a relationship with a faculty member early on can make the difference between acceptance and rejection. Many academic programs rely heavily on faculty input when deciding which students to admit, so make sure you've secured a supervisor who supports your research goals.
        </Typography>

        <Typography variant="body1" paragraph>
          By following these steps and prioritizing early supervisor contact, you'll set yourself up for success in the competitive world of graduate studies.
        </Typography>

        <SuggestedReads currentPostId={4} blogPosts={blogPosts} />
      </Container>
    </Box>
  );
}

function ImageBox({ src, alt }) {
  return (
    <Box sx={{ my: 4, width: '100%', height: '300px', position: 'relative', overflow: 'hidden' }}>
      <img 
        src={src}
        alt={alt}
        style={{ 
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '100%',
          height: '100%',
          objectFit: 'cover', 
          objectPosition: 'center',
          borderRadius: '8px' 
        }} 
      />
    </Box>
  );
}

export default HowToApplyToGraduateStudies;