import React, { useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Button,
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
  IconButton,
  Link,
} from '@mui/material';
import { Email, LinkedIn, PostAdd, Sort, Psychology, FindInPage, AssignmentTurnedIn, Route } from '@mui/icons-material';
import LazyImage from './LazyImage';

const LandingPage = ({ refs }) => {
  const handleEmailClick = () => {
    window.location.href = "mailto:inquiries@gradfinder.ca?subject=Inquiry from Gradfinder Website";
  };

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    "name": "GradFinder",
    "url": "https://gradfinder.ca",
    "description": "Connecting Graduate Supervisors with Students"
  };

  return (
    <Box>
      <Helmet>
        <title>GradFinder - Connecting Graduate Supervisors with Students</title>
        <meta name="description" content="GradFinder helps graduate students find supervisors and helps supervisors find the right students for their research teams. Streamline your graduate studies journey with our innovative platform." />
        <meta name="keywords" content="graduate studies, research opportunities, academic supervisors, thesis-based programs, GradFinder platform, graduate student matching, research collaboration, academic networking, graduate school applications" />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      {/* Banner */}
      <Box
        sx={{
          backgroundImage: `
            linear-gradient(to right, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 100%), 
            url(images/Landing_page_banner.webp)
          `,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: { xs: '500px', sm: '600px', md: '700px' },
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Container maxWidth="md" sx={{ ml: { xs: 2, md: 8 } }}>
          <Box sx={{ maxWidth: { xs: '90%', sm: '80%', md: '60%' } }}>
            <Typography variant="h1" component="h1" color="white" gutterBottom sx={{ fontSize: { xs: '3rem', sm: '4rem', md: '5rem' } }}>
              Welcome to GradFinder
            </Typography>
            <Typography variant="h4" color="white" paragraph sx={{ fontSize: { xs: '1rem', sm: '1.5rem', md: '2rem' } }}>
              Connecting Graduate supervisors with students
            </Typography>
            <Button 
              variant="contained" 
              size="large"
              onClick={handleEmailClick}
              sx={{
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                color: 'black',
                borderRadius: '50px',
                padding: '10px 30px',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 1)',
                }
              }}
            >
              Email Us
            </Button>
          </Box>
        </Container>
      </Box>

      {/* What is Gradfinder? */}
      <Box sx={{ py: { xs: 4, sm: 6, md: 8 } }} ref={refs.whatIsRef}>
        <Container>
          <Typography variant="h2" gutterBottom align="center" sx={{ fontSize: { xs: '2.5rem', sm: '3.5rem', md: '4.5rem' } }}>
            What is GradFinder?
          </Typography>
          <Typography variant="h4" paragraph align="center" sx={{ fontSize: { xs: '1rem', sm: '2rem', md: '3rem' } }}>
            GradFinder is a centralized platform designed to bridge the gap between graduate students and supervisors. 
          </Typography>
        </Container>
      </Box>

      {/* Our Mission */}
      <Box sx={{ py: { xs: 4, sm: 6, md: 8 } }} ref={refs.missionRef}>
        <Container>
          <Typography variant="h2" gutterBottom align="center" sx={{fontSize: { xs: '2.5rem', sm: '3.5rem', md: '4.5rem' } }}>
            Our Mission
          </Typography>
          <Typography variant="h4" paragraph align="center" sx={{ fontSize: { xs: '1rem', sm: '2rem', md: '3rem' } }}>
            We aim to increase access to graduate student positions and improve efficiency of student recruitment for graduate supervisors.
          </Typography>
        </Container>
      </Box>

      {/* Gradfinder for Supervisors */}
      <Box sx={{ py: { xs: 6, sm: 8, md: 12 }, bgcolor: 'background.paper' }} ref={refs.supervisorsRef}>
        <Container maxWidth="lg">
          <Typography variant="h2" gutterBottom align="center" sx={{ mb: { xs: 3, sm: 4, md: 6 },  fontSize: { xs: '2.5rem', sm: '3.5rem', md: '4.5rem'}}}>
            Gradfinder for Supervisors
          </Typography>
          <Grid container spacing={{ xs: 4, sm: 6 }}>
            {[
              {
                icon: <PostAdd sx={{ fontSize: { xs: 40, sm: 50, md: 60 } }} />,
                title: "Centralized Posting",
                description: "Gradfinder offers graduate student position posting to a centralized platform that candidates can apply to."
              },
              {
                icon: <Sort sx={{ fontSize: { xs: 40, sm: 50, md: 60 } }} />,
                title: "Organized Information",
                description: "Candidate information is automatically organized with key information intuitively displayed to easily sort and select candidates."
              },
              {
                icon: <Psychology sx={{ fontSize: { xs: 40, sm: 50, md: 60 } }} />,
                title: "AI-Powered Recommendations",
                description: "Leveraging artificial intelligence, candidates are sorted and recommended according to traits sought by graduate supervisors."
              }
            ].map((item, index) => (
              <Grid item xs={12} md={4} key={index}>
                <Card sx={{ 
                  height: '100%', 
                  display: 'flex', 
                  flexDirection: 'column',
                  borderRadius: 4,
                  boxShadow: 6,
                  transition: '0.3s',
                  '&:hover': {
                    transform: 'translateY(-5px)',
                    boxShadow: 8,
                  },
                }}>
                  <CardContent sx={{ 
                    flexGrow: 1, 
                    display: 'flex', 
                    flexDirection: 'column',
                    p: { xs: 2, sm: 3, md: 4 }, 
                  }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
                      {item.icon}
                    </Box>
                    <Typography variant="h5" component="div" gutterBottom align="center" sx={{ mb: 3 , fontSize: { xs: '0.75rem', sm: '1rem', md: '1.25rem' }}}>
                      {item.title}
                    </Typography>
                    <Typography variant="body1" align="center" color="text.secondary" sx={{ flexGrow: 1, lineHeight: 1.6 , fontSize: { xs: '0.75rem', sm: '1rem', md: '1.25rem' }}}>
                      {item.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* GradFinder for students */}
      <Box sx={{ py: { xs: 6, sm: 8, md: 12 }, bgcolor: 'background.paper' }} ref={refs.studentsRef}>
        <Container maxWidth="lg">
          <Typography variant="h2" gutterBottom align="center" sx={{ mb: { xs: 3, sm: 4, md: 6 }, fontSize: { xs: '2.5rem', sm: '3.5rem', md: '4.5rem' } }}>
            GradFinder for students
          </Typography>
          <Grid container spacing={{ xs: 4, sm: 6 }}>
            {[
              {
                icon: <FindInPage sx={{ fontSize: { xs: 40, sm: 50, md: 60 } }} />,
                title: "Centralized Searching",
                description: "Graduate student positions can be searched and filtered, increasing exposure to opportunities while reducing time spent looking"
              },
              {
                icon: <AssignmentTurnedIn  sx={{ fontSize: { xs: 40, sm: 50, md: 60 } }} />,
                title: "Easy application",
                description: "Your GradFinder profile is the majority of your application making it easy to apply, and GradFinder gives you the opportunity to explain why you are interested in the position"
              },
              {
                icon: <Route   sx={{ fontSize: { xs: 40, sm: 50, md: 60 } }} />,
                title: "Guided application",
                description: "The information to include in your application can be a daunting task, but GradFinder guides you through the process improving the quality of your application to a supervisor"
              }
            ].map((item, index) => (
              <Grid item xs={12} md={4} key={index}>
                <Card sx={{ 
                  height: '100%', 
                  display: 'flex', 
                  flexDirection: 'column',
                  borderRadius: 4,
                  boxShadow: 6,
                  transition: '0.3s',
                  '&:hover': {
                    transform: 'translateY(-5px)',
                    boxShadow: 8,
                  },
                }}>
                  <CardContent sx={{ 
                    flexGrow: 1, 
                    display: 'flex', 
                    flexDirection: 'column',
                    p: { xs: 2, sm: 3, md: 4 },
                  }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
                      {item.icon}
                    </Box>
                    <Typography variant="h5" component="div" gutterBottom align="center" sx={{ mb: 3 , fontSize: { xs: '0.75rem', sm: '1rem', md: '1.25rem' }}}>
                      {item.title}
                    </Typography>
                    <Typography variant="body1" align="center" color="text.secondary" sx={{ flexGrow: 1, lineHeight: 1.6 , fontSize: { xs: '0.75rem', sm: '1rem', md: '1.25rem' }}}>
                      {item.description}
                    </Typography>
                  </CardContent>
                  </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* Alpha Version */}
      <Box sx={{ py: { xs: 4, sm: 6, md: 8 } }} ref={refs.alphaVersionRef}>
        <Container>
          <Typography variant="h2" gutterBottom align="center" sx={{ fontSize: { xs: '2.5rem', sm: '3.5rem', md: '4.5rem' } }}>
            Alpha Version
          </Typography>
          <Typography variant="h4" paragraph align="center" sx={{ fontSize: { xs: '1rem', sm: '2rem', md: '3rem' } }}>
            GradFinder is in the Alpha Version development stage, testing will begin in 2024.  
          </Typography>
          <Typography variant="h4" paragraph align="center" sx={{ fontSize: { xs: '1rem', sm: '2rem', md: '3rem' } }}>
             If you are a prospective or current graduate student or a graduate supervisor interested in testing the alpha version of GradFinder, please contact us through the information below. 
          </Typography>
        </Container>
      </Box>

      {/* Contact Us */}
      <Box sx={{ py: { xs: 4, sm: 6, md: 8 } }} ref={refs.contactRef}>
        <Container>
          <Typography variant="h2" gutterBottom align="center" sx={{ fontSize: { xs: '2.5rem', sm: '3.5rem', md: '4.5rem' } }}>
            Contact Us
          </Typography>
          <Typography variant="h4" paragraph align="center" sx={{ fontSize: { xs: '1rem', sm: '2rem', md: '3rem' } }}>
            Have questions or want to learn more about Gradfinder? We'd love to hear from you! Send us an email at{' '}
            <Link href={`mailto:inquiries@gradfinder.ca`} color="inherit">
              inquiries@gradfinder.ca
            </Link>. 
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <Button
              variant="contained"
              size="large"
              startIcon={<Email />}
              sx={{
                backgroundColor: 'rgba(10, 10, 10, 0.9)',
                color: 'white',
                borderRadius: '50px',
                padding: '10px 30px',
                '&:hover': {
                  backgroundColor: 'rgba(10, 10, 10, 1)',
                }
              }}
              onClick={handleEmailClick}
            >
              Email Us
            </Button>
          </Box>
        </Container>
      </Box>

      {/* Founders */}
      <Container sx={{ my: { xs: 4, sm: 6, md: 8 } }} ref={refs.teamRef}>
        <Typography variant="h2" align="center" gutterBottom sx={{ fontSize: { xs: '2.5rem', sm: '3.5rem', md: '4.5rem' } }}>
          Founders
        </Typography>
        <Grid container spacing={4} justifyContent="center">
          {[
            { 
              name: 'Devon Bath', 
              image: 'images/Devon 2.jpg',
              linkedIn: 'https://www.linkedin.com/in/devon-bath-356306250/',
              email: 'devonbath@gradfinder.ca'
            },
            { 
              name: 'Naim Ben Younes', 
              image: 'images/Naim 2.jpg',
              linkedIn: 'https://www.linkedin.com/in/mohamed-naim-ben-younes-2b68a2214/',
              email: 'naimbenyounes@gradfinder.ca'
            }
          ].map((member, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card sx={{ 
                maxWidth: 345, 
                mx: 'auto',
                borderRadius: '16px',
                boxShadow: 3,
                height: '100%',
                display: 'flex',
                flexDirection: 'column'
              }}>
                <LazyImage
                  src={member.image}
                  alt={member.name}
                  style={{ 
                    height: 300,
                    width: '100%',
                    objectFit: 'cover', 
                    objectPosition: 'center 20%',
                    borderRadius: '16px 16px 0 0',
                  }}
                />
                <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Typography gutterBottom variant="h5" component="div">
                    {member.name}
                  </Typography>
                  <Box sx={{ mt: 'auto' }}>
                    <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                      <Email sx={{ mr: 1, fontSize: 20 }} />
                      <Link href={`mailto:${member.email}`} color="inherit">
                        {member.email}
                      </Link>
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <IconButton 
                      aria-label="linkedin" 
                      onClick={() => window.open(member.linkedIn, '_blank')}
                    >
                      <LinkedIn />
                    </IconButton>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

// Custom hook to expose refs
export const useLandingPageRefs = () => {
  const whatIsRef = useRef(null);
  const missionRef = useRef(null);
  const supervisorsRef = useRef(null);
  const studentsRef = useRef(null);
  const alphaVersionRef = useRef(null);
  const contactRef = useRef(null);
  const teamRef = useRef(null);

  return {
    whatIsRef,
    missionRef,
    supervisorsRef,
    studentsRef,
    alphaVersionRef,
    contactRef,
    teamRef
  };
};

export default LandingPage;