import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Typography, Grid, Card, CardContent, Button, Box } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import Navbar from './Navbar';
import { PUBLIC_URL } from '../config';

export const blogPosts = [
  { 
    id: 1, 
    title: "What is graduate school?", 
    summary: "Exploring the landscape of graduate education, including types of programs and what to expect.",
    image: "images/blog1.png"
  },
  { 
    id: 2, 
    title: "Why Pursue Graduate Studies?", 
    summary: "Discover the key reasons why people choose to embark on the journey of graduate studies, from career advancement to personal fulfillment.",
    image: "images/blog2.png" 
  },
  { 
    id: 3, 
    title: "Where Should You Take Graduate Studies?", 
    summary: "Explore the key factors to consider when choosing where to pursue your graduate studies, from institution reputation to cultural fit.",
    image: "images/blog3.png" 
  },
  { 
    id: 4, 
    title: "How to Apply to Graduate Studies: Academic vs. Professional Degrees", 
    summary: "A comprehensive guide to navigating the application process for both academic and professional graduate programs, with a focus on reaching out to potential supervisors.",
    image: "images/blog4.webp" 
  },
  { 
    id: 5, 
    title: "How to Reach Out to a Potential Supervisor for an Academic Degree", 
    summary: "Learn how to approach potential supervisors for your graduate studies, from identifying the right fit to crafting a compelling first message.",
    image: "images/blog5.png" 
  },
  { 
    id: 6, 
    title: "Introducing GradFinder: Streamlining Your Search for Thesis-Based Graduate Opportunities", 
    summary: "Discover GradFinder, a new platform designed to connect students with potential supervisors for thesis-based academic programs, simplifying the graduate school application process.",
    image: "images/blog6.svg" 
  },
];

function BlogPage() {
  console.log('Blog posts:', blogPosts); // Add this line

  return (
    <Box>
      <Helmet>
        <title>GradFinder Blog - Graduate Studies Resources and Advice</title>
        <meta name="description" content="Explore GradFinder's blog for valuable insights on graduate studies, research opportunities, and academic career guidance." />
        <meta name="keywords" content="graduate school blog, academic resources, graduate studies advice, research tips, academic career guidance" />
      </Helmet>
      <Navbar isScrolled={true} />
      <Container maxWidth="lg" sx={{ mt: 12, mb: 4 }}>
        <Typography variant="h2" component="h1" gutterBottom align="center" sx={{ mb: 6 }}>
          GradFinder Blog
        </Typography>
        <Grid container spacing={4}>
          {blogPosts.map(post => (
            <Grid item xs={12} md={4} key={post.id}>
              <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <Box
                  sx={{
                    height: 200,
                    position: 'relative',
                    overflow: 'hidden',
                    bgcolor: 'grey.300', // Keep this background color as a fallback
                  }}
                >
                  {post.image && (
                    <img
                      src={`${PUBLIC_URL}/${post.image}`}
                      alt={post.title}
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                      }}
                    />
                  )}
                  {!post.image && (
                    <Typography variant="h6" sx={{ color: 'text.secondary', textAlign: 'center', p: 2 }}>
                      No image available
                    </Typography>
                  )}
                </Box>
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography gutterBottom variant="h5" component="h2">
                    {post.title}
                  </Typography>
                  <Typography>
                    {post.summary}
                  </Typography>
                </CardContent>
                <Box sx={{ p: 2 }}>
                  <Button 
                    variant="contained" 
                    component={RouterLink} 
                    to={`/blog/${post.id}`}
                    sx={{
                      backgroundColor: 'rgba(10, 10, 10, 0.9)',
                      color: 'white',
                      borderRadius: '50px',
                      padding: '10px 30px',
                      '&:hover': {
                        backgroundColor: 'rgba(10, 10, 10, 1)',
                      }
                    }}
                  >
                    Read More
                  </Button>
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

export default BlogPage;