import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Typography, Box, List, ListItem, ListItemText, Divider } from '@mui/material';
import Navbar from '../Navbar';
import SuggestedReads from '../SuggestedReads';
import { blogPosts } from '../BlogPage';

function ImageBox({ src, alt }) {
  return (
    <Box sx={{ my: 4, width: '100%', height: '300px', position: 'relative', overflow: 'hidden' }}>
      <img 
        src={src}
        alt={alt}
        style={{ 
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '100%',
          height: '100%',
          objectFit: 'cover', 
          objectPosition: 'center',
          borderRadius: '8px' 
        }} 
      />
    </Box>
  );
}

function HowToReachOutToSupervisor() {
  const sections = [
    {
      title: "1. Why Reaching Out to a Supervisor is Essential",
      content: "For academic degrees, especially those requiring original research, the supervisor-student relationship is critical. A supervisor will guide your research, provide feedback, and support your academic development throughout your program. Reaching out to a potential supervisor before applying allows you to:",
      listItems: [
        "Confirm mutual interest: Make sure your research interests align with the professor's work.",
        "Secure a spot in the program: Some programs only admit students if a faculty member agrees to supervise them.",
        "Tailor your application: Knowing a professor is interested in working with you can help you refine your research proposal and increase your chances of acceptance."
      ],
    },
    {
      title: "2. Identifying the Right Supervisor",
      content: "Finding the right supervisor is about more than just finding someone who works in your field of interest. You'll want to look for someone whose research, mentoring style, and availability fit your needs. Here's how to begin:",
      listItems: [
        "Research faculty profiles: Start by looking at the faculty's research profiles on the university's website. Review their publications and recent projects to get a sense of their areas of expertise and current work.",
        "Read their papers: Dive into the latest articles or papers they've published. This will not only show that you're serious about their research but also help you determine if your interests align.",
        "Talk to current or former students: If possible, reach out to current students or alumni who have worked with the professor. They can give you insights into the supervisor's mentoring style, availability, and the overall lab or research environment."
      ],
    },
    {
      title: "3. Crafting the Perfect Email to a Potential Supervisor",
      content: "Once you've identified potential supervisors, it's time to reach out. Your initial email is crucial, as it forms the first impression and can determine whether or not a professor will consider working with you. Here's how to craft an effective message:",
      listItems: [
        "Subject Line: Keep it clear and professional. Example: 'Prospective PhD Student Interested in [Research Area]'",
        "Introduction: Start by introducing yourself briefly, mentioning your academic background and current status.",
        "Why You're Contacting Them: Explain why you're reaching out to this particular professor. Be specific about what draws you to their work.",
        "Your Research Interests: Give a brief overview of your research interests or the questions you hope to explore during your graduate studies.",
        "Why You're a Good Fit: Briefly mention any relevant experience, such as research projects, internships, or coursework.",
        "Request for a Conversation: End by asking if they would be open to further discussing potential supervision and your research interests.",
        "Signature: Sign off politely with your full name and include your contact details."
      ],
    },
    {
      title: "4. Avoid Impersonal Emails—They Get Ignored",
      content: "It can be tempting to send out mass, generic emails to multiple professors, especially when you're trying to contact several at once. However, this approach is one of the quickest ways to be ignored. Professors receive countless impersonal emails and quickly dismiss them for several reasons:",
      listItems: [
        "Lack of specificity: Generic emails don't demonstrate that you've taken the time to research the professor's work or how your research aligns with theirs.",
        "Feels like spam: Mass emails often feel like spam, which reduces your credibility.",
        "Misses the mark: Professors are more likely to respond to a student who clearly articulates why they're a good fit for the research team."
      ],
    },
    {
      title: "5. Following Up",
      content: "Professors are often busy, and it's not uncommon for emails to get lost in the shuffle. If you don't hear back within two weeks, it's okay to send a polite follow-up message. Keep it short and respectful, simply reminding them of your initial email and expressing your continued interest.",
      listItems: [],
    },
    {
      title: "6. Preparing for a Meeting or Response",
      content: "If a professor responds positively, you may be asked to discuss your research interests further or have a formal interview. Prepare for this by:",
      listItems: [
        "Clarifying your research goals: Be ready to discuss your research in more detail, including why you're interested in their specific area and what contributions you hope to make.",
        "Asking about the program: Don't hesitate to ask about the department's resources, current projects, or the supervisor's expectations of graduate students.",
        "Being flexible: Supervisors might suggest modifications to your proposed research based on their ongoing projects or available funding. Be open to feedback and suggestions."
      ],
    },
    {
      title: "7. What If They're Not Accepting Students?",
      content: "It's possible that a professor you're interested in won't be able to take on new students due to limited availability or funding. If that happens, don't be discouraged. They may suggest other faculty members or programs that align with your interests. Keep exploring other options and reaching out to potential supervisors.",
      listItems: [],
    },
  ];

  const images = [
    "/images/blog5-1.png",
    "/images/blog5-2.png",
    "/images/blog5-3.png",
    "/images/blog5-4.png",
  ];

  return (
    <Box>
      <Helmet>
        <title>How to Reach Out to a Potential Supervisor for an Academic Degree | GradFinder</title>
        <meta name="description" content="Learn how to approach potential supervisors for your graduate studies, from identifying the right fit to crafting a compelling first message. Improve your chances of securing a great supervisor." />
        <meta name="keywords" content="contacting potential supervisors, academic networking, research interests, supervisor-student relationship, graduate research opportunities" />
      </Helmet>
      <Navbar isScrolled={true} />
      <Container maxWidth="md" sx={{ mt: 12, mb: 4 }}>
        <Typography variant="h1" component="h1" gutterBottom align="center" sx={{ mb: 4, fontWeight: 'bold' }}>
          How to Reach Out to a Potential Supervisor for an Academic Degree
        </Typography>
        <Typography variant="h5" paragraph align="center" sx={{ mb: 6, fontStyle: 'italic', color: 'text.secondary' }}>
          A guide to approaching potential supervisors and crafting compelling first messages
        </Typography>
        
        <Typography variant="body1" paragraph>
          If you're considering pursuing a thesis-based master's or PhD program, one of the most important steps in your application process is reaching out to potential supervisors. Unlike professional programs, where admission decisions are primarily made by a central admissions committee, thesis-based academic programs rely heavily on individual faculty members' willingness to supervise your research.
        </Typography>

        <Typography variant="body1" paragraph>
          In this post, we'll guide you through how to approach potential supervisors, from identifying the right fit to crafting a compelling first message.
        </Typography>

        <ImageBox src={images[0]} alt="Reaching out to potential supervisors overview" style={{ width: '100%', maxWidth: '600px', margin: '0 auto', display: 'block' }} />

        {sections.map((section, index) => (
          <React.Fragment key={index}>
            <Divider sx={{ my: 4 }} />
            <Typography variant="h3" gutterBottom sx={{ fontWeight: 'bold' }}>
              {section.title}
            </Typography>
            <Typography variant="body1" paragraph>
              {section.content}
            </Typography>
            {section.listItems.length > 0 && (
              <List>
                {section.listItems.map((item, itemIndex) => (
                  <ListItem key={itemIndex}>
                    <ListItemText secondary={item} />
                  </ListItem>
                ))}
              </List>
            )}
            {(index === 1 || index === 2 || index === 4) && 
              <ImageBox 
                src={images[index === 1 ? 1 : index === 2 ? 2 : 3]} 
                alt={`Illustration for ${section.title}`} 
              />
            }
          </React.Fragment>
        ))}

        <Divider sx={{ my: 4 }} />

        <Typography variant="h2" gutterBottom sx={{ mt: 6, mb: 4, fontWeight: 'bold' }}>
          Final Thoughts: Building a Strong Connection
        </Typography>

        <Typography variant="body1" paragraph>
          Reaching out to a potential supervisor is more than just a formality—it's about building a professional relationship. The better prepared and thoughtful you are in your initial contact, the more likely you are to find a supervisor who is eager to work with you.
        </Typography>

        <Typography variant="body1" paragraph>
          By doing your homework and approaching professors with genuine interest and respect for their work, you will significantly improve your chances of securing a great supervisor—and setting the foundation for a successful academic career.
        </Typography>

        <SuggestedReads currentPostId={5} blogPosts={blogPosts} />
      </Container>
    </Box>
  );
}

export default HowToReachOutToSupervisor;