import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const LazyImage = ({ src, alt, style, onLoad, onError, ...props }) => {
  const [error, setError] = useState(false);

  const handleError = (e) => {
    console.error(`Failed to load image: ${src}`);
    console.error(e);
    setError(true);
    if (onError) onError(e);
  };

  const handleLoad = () => {
    console.log(`Successfully loaded image: ${src}`);
    if (onLoad) onLoad();
  };

  if (error) {
    return <img src={src} alt={alt} style={style} {...props} />;
  }

  return (
    <LazyLoadImage
      alt={alt}
      src={src}
      effect="blur"
      style={style}
      onError={handleError}
      afterLoad={handleLoad}
      {...props}
    />
  );
};

export default LazyImage;