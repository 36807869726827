import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Typography, Box, List, ListItem, ListItemText } from '@mui/material';
import Navbar from '../Navbar';
import SuggestedReads from '../SuggestedReads';
import { blogPosts } from '../BlogPage';

function WhyPursueGraduateStudies() {
  const images = [
    { src: "/images/blog2-1.png", alt: "Graduate studies overview", position: "center 30%" },
    { src: "/images/blog2-2.png", alt: "Deepening knowledge", position: "center 40%" },
    { src: "/images/blog2-3.png", alt: "Career advancement", position: "center 25%" },
    { src: "/images/blog2-4.png", alt: "Innovation and research", position: "center 35%" }
  ];

  return (
    <Box>
      <Helmet>
        <title>Why Pursue Graduate Studies? | GradFinder</title>
        <meta name="description" content="Discover the key reasons to pursue graduate studies, from career advancement to personal fulfillment. Explore the benefits of advanced education with GradFinder." />
        <meta name="keywords" content="benefits of graduate studies, career advancement, research opportunities, academic growth, specialized knowledge" />
      </Helmet>
      <Navbar isScrolled={true} />
      <Container maxWidth="md" sx={{ mt: 12, mb: 4 }}>
        <Typography variant="h1" component="h1" gutterBottom align="center" sx={{ mb: 4, fontWeight: 'bold' }}>
          Why Pursue Graduate Studies?
        </Typography>
        <Typography variant="h5" paragraph align="center" sx={{ mb: 6, fontStyle: 'italic', color: 'text.secondary' }}>
          Exploring the motivations and benefits of advanced education
        </Typography>
        
        <Typography variant="body1" paragraph>
          Deciding to pursue graduate studies is a significant commitment of time, effort, and resources. But it can be a powerful step forward for many professionals and scholars. Here are some key reasons why people choose to embark on this journey.
        </Typography>

        <ImageBox image={images[0]} />

        <Typography variant="h3" gutterBottom sx={{ mt: 6, mb: 3, fontWeight: 'bold' }}>
          1. Deepen Knowledge in a Specific Field
        </Typography>
        <Typography variant="body1" paragraph>
          Graduate programs allow students to specialize in areas they are passionate about. Whether it's conducting research to push the boundaries of a field or gaining deeper professional knowledge, graduate studies provide the opportunity to become an expert.
        </Typography>
        <List>
          <ListItem>
            <ListItemText secondary="In academic programs, students can explore original research and make contributions to their discipline." />
          </ListItem>
          <ListItem>
            <ListItemText secondary="In professional programs, students gain advanced skills that are directly applicable to their careers." />
          </ListItem>
        </List>

        <ImageBox image={images[1]} />

        <Typography variant="h3" gutterBottom sx={{ mt: 6, mb: 3, fontWeight: 'bold' }}>
          2. Advance Career Opportunities
        </Typography>
        <Typography variant="body1" paragraph>
          For many industries, especially those requiring advanced technical skills, a graduate degree is either a necessity or a strong advantage. It can unlock higher-level positions, greater responsibility, and better compensation (Altonji and Zhong 2020).
        </Typography>
        <List>
          <ListItem>
            <ListItemText 
              primary={<Typography variant="body1" sx={{ fontWeight: 'bold' }}>Professional Degrees:</Typography>}
              secondary="Programs such as MBA, MD, or JD are often essential for entering certain careers or progressing into leadership roles."
            />
          </ListItem>
          <ListItem>
            <ListItemText 
              primary={<Typography variant="body1" sx={{ fontWeight: 'bold' }}>Academic Degrees:</Typography>}
              secondary="Many academic careers in teaching, research, or highly specialized sectors require a PhD or master's."
            />
          </ListItem>
        </List>

        <ImageBox image={images[2]} />

        <Typography variant="h3" gutterBottom sx={{ mt: 6, mb: 3, fontWeight: 'bold' }}>
          3. Expand Your Professional Network
        </Typography>
        <Typography variant="body1" paragraph>
          Graduate school offers a unique opportunity to connect with like-minded individuals, professors, and industry professionals. These relationships can lead to collaborations, mentorship, and future career opportunities.
        </Typography>
        <List>
          <ListItem>
            <ListItemText secondary="Programs often involve working closely with professors or advisors, creating lasting professional connections." />
          </ListItem>
          <ListItem>
            <ListItemText secondary="Peers in graduate school can become future colleagues or partners in your field." />
          </ListItem>
        </List>

        <Typography variant="h3" gutterBottom sx={{ mt: 6, mb: 3, fontWeight: 'bold' }}>
          4. Increase Earning Potential
        </Typography>
        <Typography variant="body1" paragraph>
          Many studies show that advanced degrees are correlated with higher lifetime earnings. Certain fields, such as business, law, medicine, and engineering, reward advanced qualifications with higher salaries.
        </Typography>
        <List>
          <ListItem>
            <ListItemText secondary="On average, professionals with a graduate degree tend to earn more than those with just a bachelor's degree." />
          </ListItem>
          <ListItem>
            <ListItemText secondary="Specialized skills and qualifications from graduate studies can make you a more competitive candidate for high-paying jobs." />
          </ListItem>
        </List>

        <Typography variant="h3" gutterBottom sx={{ mt: 6, mb: 3, fontWeight: 'bold' }}>
          5. Personal Fulfillment
        </Typography>
        <Typography variant="body1" paragraph>
          Pursuing graduate studies is not just about career advancement—it can also be a deeply fulfilling personal achievement. The intellectual challenge and the satisfaction of mastering a subject area are highly motivating for many students.
        </Typography>
        <List>
          <ListItem>
            <ListItemText secondary="Completing a thesis or dissertation is a major accomplishment that gives a sense of pride and personal growth." />
          </ListItem>
          <ListItem>
            <ListItemText secondary="The process of learning and contributing to your field can be intrinsically rewarding, especially for those passionate about their area of study." />
          </ListItem>
        </List>

        <ImageBox image={images[3]} />

        <Typography variant="h3" gutterBottom sx={{ mt: 6, mb: 3, fontWeight: 'bold' }}>
          6. Contribute to Innovation and Research
        </Typography>
        <Typography variant="body1" paragraph>
          For those interested in academia or research, graduate studies provide the tools to create new knowledge. Whether through scientific discovery, policy development, or theoretical work, graduate students play a crucial role in advancing their fields.
        </Typography>
        <List>
          <ListItem>
            <ListItemText secondary="PhD students, in particular, are at the forefront of new research, pushing the boundaries of what is known and understood." />
          </ListItem>
          <ListItem>
            <ListItemText secondary="Many industries rely on innovations that come from graduate-level research, such as technology, medicine, and engineering." />
          </ListItem>
        </List>

        <Typography variant="h4" gutterBottom sx={{ mt: 6, mb: 2, fontWeight: 'bold' }}>
          References
        </Typography>
        <Typography variant="body1" paragraph>
          Altonji, J., & Zhong, L. (2020). The Labor Market Returns to Advanced Degrees. Journal of Labor Economics, 39, 303 - 360. https://doi.org/10.1086/710959.
        </Typography>

        <SuggestedReads currentPostId={2} blogPosts={blogPosts} />
      </Container>
    </Box>
  );
}

function ImageBox({ image }) {
  return (
    <Box sx={{ my: 4, width: '100%', height: '300px', position: 'relative', overflow: 'hidden' }}>
      <img 
        src={image.src}
        alt={image.alt}
        style={{ 
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '100%',
          height: '100%',
          objectFit: 'cover', 
          objectPosition: image.position,
          borderRadius: '8px' 
        }} 
      />
    </Box>
  );
}

export default WhyPursueGraduateStudies;