import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Button, Box, IconButton, Menu, MenuItem, useMediaQuery } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

const Navbar = ({ isScrolled, landingPageRefs }) => {
  const [mobileMenuAnchorEl, setMobileMenuAnchorEl] = useState(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/') {
      const sectionToScroll = sessionStorage.getItem('scrollTo');
      if (sectionToScroll) {
        const ref = landingPageRefs[sectionToScroll];
        if (ref && ref.current) {
          ref.current.scrollIntoView({ behavior: 'smooth' });
          sessionStorage.removeItem('scrollTo');
        }
      }
    }
  }, [location, landingPageRefs]);

  const handleMobileMenuOpen = (event) => {
    setMobileMenuAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMenuAnchorEl(null);
  };

  const scrollToSection = (refKey) => {
    if (location.pathname !== '/') {
      sessionStorage.setItem('scrollTo', refKey);
      navigate('/');
    } else {
      const ref = landingPageRefs[refKey];
      if (ref && ref.current) {
        ref.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
    handleMobileMenuClose();
  };

  const handleLogoClick = () => {
    navigate('/');
    window.scrollTo(0, 0);
  };

  const navItems = [
    { label: 'About', refKey: 'whatIsRef' },
    { label: 'Our Mission', refKey: 'missionRef' },
    { label: 'For Supervisors', refKey: 'supervisorsRef' },
    { label: 'For Students', refKey: 'studentsRef' },
    { label: 'Alpha Version', refKey: 'alphaVersionRef' },
    { label: 'Founders', refKey: 'teamRef' },
    { label: 'Contact', refKey: 'contactRef' },
  ];

  return (
    <AppBar 
      position="fixed" 
      sx={{
        background: isScrolled ? 'rgba(0, 0, 0, 0.8)' : 'transparent',
        boxShadow: isScrolled ? '0px 2px 4px -1px rgba(0,0,0,0.2)' : 'none',
        transition: 'background-color 0.3s ease, box-shadow 0.3s ease'
      }}
    >
      <Toolbar>
        <Box sx={{ flexGrow: 1, cursor: 'pointer' }} onClick={handleLogoClick}>
          <img 
            src="/images/logo.svg" 
            alt="Gradfinder Logo" 
            style={{ height: '40px' }} 
          />
        </Box>
        {isSmallScreen ? (
          <>
            <IconButton
              color="inherit"
              aria-label="menu"
              onClick={handleMobileMenuOpen}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={mobileMenuAnchorEl}
              open={Boolean(mobileMenuAnchorEl)}
              onClose={handleMobileMenuClose}
            >
              {navItems.map((item, index) => (
                <MenuItem key={index} onClick={() => scrollToSection(item.refKey)}>
                  {item.label}
                </MenuItem>
              ))}
              <MenuItem component={RouterLink} to="/blog" onClick={handleMobileMenuClose}>
                Blog
              </MenuItem>
            </Menu>
          </>
        ) : (
          <>
            {navItems.map((item, index) => (
              <Button
                key={index}
                sx={{ 
                  color: 'white',
                  fontSize: '1.1rem',
                  fontWeight: 500,
                  mx: 1
                }} 
                onClick={() => scrollToSection(item.refKey)}
              >
                {item.label}
              </Button>
            ))}
            <Button
              component={RouterLink}
              to="/blog"
              sx={{ 
                color: 'white',
                fontSize: '1.1rem',
                fontWeight: 500,
                mx: 1
              }}
            >
              Blog
            </Button>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;