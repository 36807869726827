import React from 'react';
import { Box, Container, Grid, Typography, Link } from '@mui/material';
import { LinkedIn, Instagram } from '@mui/icons-material';

const Footer = () => {
  return (
    <Box component="footer" sx={{ bgcolor: 'black', color: 'white', py: { xs: 4, sm: 5, md: 6 } }}>
      <Container maxWidth="lg">
        <Box display="flex" flexDirection="column" alignItems="center" mb={4}>
          <img 
            src="/images/ZylenixLogo.svg" 
            alt="Zylenix Logo" 
            style={{ height: '60px' }} 
          />
        </Box>
        <Grid container spacing={2} sx={{ mb: 4 }}>
          <Grid item xs={12} md={4}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: { xs: 'center', md: 'flex-start' } }}>
              <Typography variant="h6" gutterBottom sx={{ fontSize: { xs: '0.75rem', sm: '1rem', md: '1.25rem' } }}>
                Contact Us
              </Typography>
              <Typography variant="body2" sx={{ textAlign: { xs: 'center', md: 'left' } }}>
                <Link href="mailto:inquiries@gradfinder.ca" color="inherit">
                  inquiries@gradfinder.ca
                </Link>
              </Typography>
              <Typography variant="body2" sx={{ textAlign: { xs: 'center', md: 'left' } }}>
                441-100 Innovation Dr, Winnipeg, MB R3T 6A8
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4} sx={{ display: { xs: 'none', md: 'block' } }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%', justifyContent: 'center' }}>
              <Typography variant="body1" align="center">
                © 2024 Zylenix. All rights reserved.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: { xs: 'center', md: 'flex-end' },
              width: '100%'
            }}>
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: { xs: 'center', md: 'flex-start' },
                width: { xs: '100%', md: 'auto' }
              }}>
                <Typography variant="h6" gutterBottom sx={{ 
                  fontSize: { xs: '0.75rem', sm: '1rem', md: '1.25rem' },
                  textAlign: { xs: 'center', md: 'left' },
                  width: '100%'
                }}>
                  Connect with us
                </Typography>
                <Link 
                  href="https://www.linkedin.com/company/zylenix-inc/"
                  target="_blank"
                  rel="noopener noreferrer"
                  color="inherit"
                  sx={{ display: 'flex', alignItems: 'center', mb: 1, justifyContent: { xs: 'center', md: 'flex-start' } }}
                >
                  <LinkedIn sx={{ mr: 1 }} />
                  <Typography variant="body2">Zylenix</Typography>
                </Link>
                <Link 
                  href="https://www.linkedin.com/showcase/grad-finder/"
                  target="_blank"
                  rel="noopener noreferrer"
                  color="inherit"
                  sx={{ display: 'flex', alignItems: 'center', mb: 1, justifyContent: { xs: 'center', md: 'flex-start' } }}
                >
                  <LinkedIn sx={{ mr: 1 }} />
                  <Typography variant="body2">Gradfinder</Typography>
                </Link>
                <Link 
                  href="https://www.instagram.com/gradfinder_/"
                  target="_blank"
                  rel="noopener noreferrer"
                  color="inherit"
                  sx={{ display: 'flex', alignItems: 'center', justifyContent: { xs: 'center', md: 'flex-start' } }}
                >
                  <Instagram sx={{ mr: 1 }} />
                  <Typography variant="body2">Gradfinder</Typography>
                </Link>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Box sx={{ display: { xs: 'block', md: 'none' }, textAlign: 'center' }}>
          <Typography variant="body2">
            © 2024 Zylenix. All rights reserved.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;