import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Typography, Box, List, ListItem, ListItemText, Divider } from '@mui/material';
import Navbar from '../Navbar';
import SuggestedReads from '../SuggestedReads';
import { blogPosts } from '../BlogPage';

function IntroducingGradFinder() {
  const sections = [
    {
      title: "1. What is GradFinder?",
      content: "GradFinder is a new tool developed with one goal in mind: making it easier for students to find and apply for thesis-based academic opportunities. It bridges the gap between students searching for academic positions and professors looking for qualified, motivated students to supervise.",
      listItems: [
        "Supervisors post opportunities: Professors from various institutions post when they have research opportunities available.",
        "Students apply directly to supervisors: Once a professor posts an opportunity, students can apply directly through GradFinder's platform.",
        "Unlike traditional methods where you would have to craft an entirely new application for each professor, GradFinder simplifies the process."
      ],
    },
    {
      title: "2. Why Use GradFinder?",
      content: "GradFinder offers several key advantages for students, especially those applying to multiple programs or professors:",
      listItems: [
        "Profile-Based Applications: Your application is tied to your GradFinder profile. This means instead of submitting a unique application for every position, your carefully crafted profile does most of the work. You can quickly apply to several opportunities without rewriting your application every time.",
        "Minimal Extra Effort: Once your profile is complete, you can apply to supervisors with just a few clicks. This cuts down the time you spend tailoring every application and allows you to focus on other important aspects of your grad school preparation—like refining your research ideas and preparing for interviews.",
        "Encourages Comprehensive Profiles: To increase your chances of landing the right opportunity, GradFinder encourages students to fill out their profiles as fully as possible. Highlight your research interests, past experience, and any specific skills or accomplishments relevant to the supervisors you're interested in working with."
      ],
    },
    {
      title: "3. Caution: GradFinder is New",
      content: "While GradFinder holds a lot of potential, it's important to remember that it's a relatively new platform. As a result, the number of professors actively posting on the site may be limited in the early stages. However, the platform is growing, and we're working to expand the pool of opportunities.",
      listItems: [
        "For students using GradFinder, we welcome your feedback. Whether it's about the application process or the kinds of opportunities available, your input will help us improve the platform and create a better experience for both students and supervisors."
      ],
    },
    {
      title: "4. How to Make the Most of GradFinder",
      content: "Here's how you can maximize your experience on GradFinder and increase your chances of finding the right thesis-based academic opportunity:",
      listItems: [
        "Complete Your Profile: The more information you provide, the more appealing your application will be to potential supervisors. Be sure to include your academic background, research interests, and any relevant projects or experience.",
        "Tailor Applications (When Needed): While your profile handles most of the application process, it's still a good idea to add personalized notes or adjustments when applying to a specific professor. Highlight why their research aligns with your goals and how you can contribute to their projects.",
        "Keep an Eye on Opportunities: Since the platform is new, the number of opportunities may be smaller, but they will continue to grow as more supervisors join. Check regularly for new postings and stay active on the platform.",
        "Provide Feedback: Help us make GradFinder better by sharing your experience. Let us know if you encounter any issues or if there's anything you think could be improved."
      ],
    },
    {
      title: "5. Final Thoughts: A Streamlined Future for Grad School Applications",
      content: "GradFinder represents the future of applying for thesis-based academic opportunities. It saves you time, minimizes repetitive work, and connects you directly with potential supervisors. While it's still in its early stages, we encourage students to give the platform a try and share their feedback with us.",
      listItems: [
        "With GradFinder, you can focus less on administrative tasks and more on what really matters: finding the right supervisor and preparing for your academic journey."
      ],
    }
  ];

  return (
    <Box>
      <Helmet>
        <title>Introducing GradFinder: Streamlining Your Search for Thesis-Based Graduate Opportunities | GradFinder</title>
        <meta name="description" content="Discover GradFinder, a new platform designed to connect students with potential supervisors for thesis-based academic programs, simplifying the graduate school application process." />
        <meta name="keywords" content="GradFinder platform, connecting students and supervisors, streamlined application process, thesis-based opportunities, academic matchmaking" />
      </Helmet>
      <Navbar isScrolled={true} />
      <Container maxWidth="md" sx={{ mt: 12, mb: 4 }}>
        <Typography variant="h1" component="h1" gutterBottom align="center" sx={{ mb: 4, fontWeight: 'bold' }}>
          Introducing GradFinder: Streamlining Your Search for Thesis-Based Graduate Opportunities
        </Typography>
        <Typography variant="h5" paragraph align="center" sx={{ mb: 6, fontStyle: 'italic', color: 'text.secondary' }}>
          A new platform to connect students with potential supervisors
        </Typography>
        
        <Typography variant="body1" paragraph>
          In our previous blog posts, we discussed what graduate studies are, why you should pursue them, where you might consider applying, and how to approach the application process. If you're aiming for a thesis-based academic degree, you already know how crucial it is to find the right supervisor. This is where GradFinder comes in—a centralized platform designed to help students efficiently connect with potential supervisors for thesis-based academic programs.
        </Typography>

        {sections.map((section, index) => (
          <React.Fragment key={index}>
            <Divider sx={{ my: 4 }} />
            <Typography variant="h3" gutterBottom sx={{ fontWeight: 'bold' }}>
              {section.title}
            </Typography>
            <Typography variant="body1" paragraph>
              {section.content}
            </Typography>
            {section.listItems.length > 0 && (
              <List>
                {section.listItems.map((item, itemIndex) => (
                  <ListItem key={itemIndex}>
                    <ListItemText secondary={item} />
                  </ListItem>
                ))}
              </List>
            )}
          </React.Fragment>
        ))}

        <SuggestedReads currentPostId={6} blogPosts={blogPosts} />
      </Container>
    </Box>
  );
}

export default IntroducingGradFinder;