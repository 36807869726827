import React, { useState, useEffect, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import { ThemeProvider, createTheme, CssBaseline } from '@mui/material';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import LandingPage, { useLandingPageRefs } from './components/LandingPage';
import BlogPage from './components/BlogPage';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import WhatAreGraduateStudies from './components/blogs/WhatAreGraduateStudies';
import WhyPursueGraduateStudies from './components/blogs/WhyPursueGraduateStudies';
import WhereToTakeGraduateStudies from './components/blogs/WhereToTakeGraduateStudies';
import HowToApplyToGraduateStudies from './components/blogs/HowToApplyToGraduateStudies';
import HowToReachOutToSupervisor from './components/blogs/HowToReachOutToSupervisor';
import IntroducingGradFinder from './components/blogs/IntroducingGradFinder';

const theme = createTheme({
  typography: {
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    h1: {
      fontWeight: 500,
      fontSize: { xs: '3rem', sm: '4rem', md: '5rem' },
    },
    h2: {
      fontWeight: 500,
      fontSize: { xs: '2.5rem', sm: '3.5rem', md: '4.5rem' },
    },
    h3: {
      fontWeight: 200,
      fontSize: { xs: '2rem', sm: '3rem', md: '4rem' },
    },
    h4: {
      fontWeight: 200,
      fontSize: { xs: '1.75rem', sm: '2.5rem', md: '3.5rem' },
    },
    h5: {
      fontWeight: 400,
      fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' },
    },
    h6: {
      fontWeight: 400,
      fontSize: { xs: '1.25rem', sm: '1.75rem', md: '2.25rem' },
    },
    body1: {
      fontWeight: 300,
      fontSize: { xs: '1rem', sm: '1.25rem', md: '1.5rem' },
    },
    body2: {
      fontWeight: 400,
      fontSize: { xs: '0.875rem', sm: '1rem', md: '1.25rem' },
    },
    button: {
      fontWeight: 400,
      textTransform: 'none', 
      fontSize: { xs: '1rem', sm: '1.25rem', md: '1.5rem' },
    },
    subtitle1: {
      fontWeight: 400,
      fontSize: { xs: '1rem', sm: '1.25rem', md: '1.5rem' },
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        },
      },
    },
  },
});

const AppContent = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();
  const landingPageRefs = useLandingPageRefs();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50 || location.pathname !== '/');
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Call it initially
    return () => window.removeEventListener('scroll', handleScroll);
  }, [location.pathname]);

  return (
    <>
      <Navbar isScrolled={isScrolled} landingPageRefs={landingPageRefs} />
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<LandingPage refs={landingPageRefs} />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/blog/1" element={<WhatAreGraduateStudies />} />
          <Route path="/blog/2" element={<WhyPursueGraduateStudies />} />
          <Route path="/blog/3" element={<WhereToTakeGraduateStudies />} />
          <Route path="/blog/4" element={<HowToApplyToGraduateStudies />} />
          <Route path="/blog/5" element={<HowToReachOutToSupervisor />} />
          <Route path="/blog/6" element={<IntroducingGradFinder />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Suspense>
      <Footer />
    </>
  );
};

const App = () => {
  return (
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <Helmet>
          <title>GradFinder - Connecting Graduate Supervisors with Students</title>
          <meta name="description" content="GradFinder is a platform connecting graduate supervisors with students. Find the perfect match for your graduate studies." />
          <meta name="keywords" content="graduate studies, supervisors, students, research, academia" />
          <meta name="author" content="Zylenix" />
          <link rel="canonical" href="https://gradfinder.ca" />
          <meta property="og:title" content="GradFinder - Connecting Graduate Supervisors with Students" />
          <meta property="og:description" content="Find the perfect match for your graduate studies." />
          <meta property="og:image" content="/images/og-image.png" />
          <meta property="og:url" content="https://gradfinder.ca" />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
        <CssBaseline />
        <Router>
          <AppContent />
        </Router>
      </ThemeProvider>
    </HelmetProvider>
  );
};

export default App;