import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Typography, Box, List, ListItem, ListItemText, Divider } from '@mui/material';
import Navbar from '../Navbar';
import SuggestedReads from '../SuggestedReads';
import { blogPosts } from '../BlogPage';

function ImageBox({ src, alt }) {
  return (
    <Box sx={{ my: 4, width: '100%', height: '300px', position: 'relative', overflow: 'hidden' }}>
      <img 
        src={src}
        alt={alt}
        style={{ 
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '100%',
          height: '100%',
          objectFit: 'cover', 
          objectPosition: 'center',
          borderRadius: '8px' 
        }} 
      />
    </Box>
  );
}

function WhereToTakeGraduateStudies() {
  const sections = [
    {
      title: "1. Institution Reputation and Ranking",
      content: "The reputation of the university or program can influence the value of your degree, especially in certain fields. High-ranking universities often have more resources, cutting-edge research facilities, and a global network of alumni.",
      listItems: [
        "Academic programs: If you're pursuing a PhD or thesis-based master's, the strength of the research programs and faculty should be a top priority.",
        "Professional programs: For degrees like an MBA, JD, or MD, the prestige of the institution can impact job prospects and networking opportunities."
      ],
    },
    {
      title: "2. Faculty and Research Opportunities",
      content: "In academic programs, your relationship with your advisor or faculty mentor is crucial. Therefore, the expertise of the faculty in your area of interest should be a key consideration.",
      listItems: [
        "Faculty members who are leaders in your field and whose research interests align with yours.",
        "Research facilities that support your goals, such as well-funded labs, libraries, or partnerships with industries or organizations relevant to your field."
      ],
    },
    {
      title: "3. Program Fit",
      content: "Not all programs are the same, even within the same field. It's essential to find a program that aligns with your academic or professional goals.",
      listItems: [
        "Curriculum focus: Does the program offer the courses or specialization you need? For professional degrees, does it provide the necessary certifications or practical experience?",
        "Degree structure: Consider whether a course-based or research-based program is more suited to your learning style and career goals."
      ],
    },
    {
      title: "4. Funding and Financial Aid",
      content: "Graduate studies can be expensive, so the availability of funding is an important factor. Many academic programs offer scholarships, fellowships, or teaching/research assistantships, while professional programs might have more limited financial aid options.",
      listItems: [
        "Scholarships and fellowships: Look for universities that offer generous funding packages to cover tuition and living expenses.",
        "Stipends for academic research: Research-based programs often offer stipends to cover living costs in exchange for teaching or research work."
      ],
    },
    {
      title: "5. Location and Lifestyle",
      content: "Where you live during your graduate studies can affect both your academic experience and quality of life.",
      listItems: [
        "City size and environment: Do you prefer a bustling urban setting or a quieter town? The cost of living, accessibility, and cultural opportunities vary widely by location.",
        "Proximity to professional or research opportunities: For some degrees, such as MBAs or engineering programs, being near a major industry hub can provide valuable networking and internship opportunities.",
        "International opportunities: Studying abroad can offer unique experiences and global perspectives. Some fields, like global health or international law, may benefit from studying in specific regions."
      ],
    },
    {
      title: "6. Alumni Network and Career Services",
      content: "A strong alumni network can open doors for job opportunities and professional connections post-graduation. Universities with a dedicated career services department and active alumni involvement can be especially helpful in professional programs.",
      listItems: [
        "Alumni involvement: Check how active the university's alumni network is, as this can lead to mentorship or job referrals.",
        "Career services: Look for programs that offer career counseling, resume reviews, and connections to internships or job placements."
      ],
    },
    {
      title: "7. Cultural Fit and Community",
      content: "The university's culture and community can make a significant difference in your graduate school experience. A supportive, inclusive, and collaborative environment can help you thrive both academically and personally.",
      listItems: [
        "Campus culture: Visit the campus, if possible, or talk to current students to understand the social environment and level of support available to graduate students.",
        "Diversity and inclusion: Consider whether the program and institution foster diversity, and if it's important for you, look into student organizations and support systems for various cultural or social groups."
      ],
    }
  ];

  const images = [
    "/images/blog3-1.png",
    "/images/blog3-2.png",
    "/images/blog3-3.png",
    "/images/blog3-4.png",
    "/images/blog3-5.png",
  ];

  return (
    <Box>
      <Helmet>
        <title>Where to Take Graduate Studies? | GradFinder</title>
        <meta name="description" content="Explore key factors to consider when choosing where to pursue your graduate studies, from institution reputation to cultural fit. Find the best graduate school for you." />
        <meta name="keywords" content="choosing graduate school, university selection criteria, academic program comparison, research facilities, funding opportunities" />
      </Helmet>
      <Navbar isScrolled={true} />
      <Container maxWidth="md" sx={{ mt: 12, mb: 4 }}>
        <Typography variant="h1" component="h1" gutterBottom align="center" sx={{ mb: 4, fontWeight: 'bold' }}>
          Where Should You Take Graduate Studies?
        </Typography>
        <Typography variant="h5" paragraph align="center" sx={{ mb: 6, fontStyle: 'italic', color: 'text.secondary' }}>
          Factors to consider when choosing your graduate school
        </Typography>
        
        <Typography variant="body1" paragraph>
          Once you've decided to pursue graduate studies and understand the reasons for doing so, the next big question is: where should you take your studies? The choice of institution and location can have a significant impact on your experience and future career. Here are some factors to consider.
        </Typography>

        <ImageBox src={images[0]} alt="Graduate studies overview" />

        {sections.map((section, index) => (
          <React.Fragment key={index}>
            <Divider sx={{ my: 4 }} />
            <Typography variant="h3" gutterBottom sx={{ fontWeight: 'bold' }}>
              {section.title}
            </Typography>
            <Typography variant="body1" paragraph>
              {section.content}
            </Typography>
            <List>
              {section.listItems.map((item, itemIndex) => (
                <ListItem key={itemIndex}>
                  <ListItemText secondary={item} />
                </ListItem>
              ))}
            </List>
            {(index === 0 || index === 1 || index === 4 || index === 5) && 
              <ImageBox 
                src={images[index === 0 ? 1 : index === 1 ? 2 : index === 4 ? 3 : 4]} 
                alt={`Illustration for ${section.title}`} 
              />
            }
          </React.Fragment>
        ))}

        <SuggestedReads currentPostId={3} blogPosts={blogPosts} />
      </Container>
    </Box>
  );
}

export default WhereToTakeGraduateStudies;