import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Typography, Box, List, ListItem, ListItemText, Divider } from '@mui/material';
import Navbar from '../Navbar';
import SuggestedReads from '../SuggestedReads';
import { blogPosts } from '../BlogPage';

function WhatAreGraduateStudies() {
  return (
    <Box>
      <Helmet>
        <title>What are Graduate Studies? | GradFinder</title>
        <meta name="description" content="Explore the landscape of graduate education, including types of programs and what to expect in graduate school." />
        <meta name="keywords" content="graduate school explanation, types of graduate degrees, academic vs professional programs, PhD programs, master's degrees" />
      </Helmet>
      <Navbar isScrolled={true} />
      <Container maxWidth="md" sx={{ mt: 12, mb: 4 }}>
        <Typography variant="h1" component="h1" gutterBottom align="center" sx={{ mb: 4, fontWeight: 'bold' }}>
          What is graduate school?
        </Typography>
        <Typography variant="h5" paragraph align="center" sx={{ mb: 6, fontStyle: 'italic', color: 'text.secondary' }}>
          Exploring the landscape of advanced education and specialization
        </Typography>
        
        <Typography variant="body1" paragraph>
          Graduate studies refer to advanced education pursued after completing an undergraduate degree. They provide an opportunity for students to specialize in a particular field of study or profession, often leading to either academic or professional qualifications. Graduate studies are divided into several types, depending on the goals and structure of the programs.
        </Typography>

        <Typography variant="h2" gutterBottom sx={{ mt: 4, mb: 3 }}>
          Types of Graduate Studies
        </Typography>
        <Typography variant="body1" paragraph>
          Graduate programs generally fall into two categories: academic or professional. The main difference is the purpose of the degree and what students aim to achieve upon completion.
        </Typography>

        <Divider sx={{ my: 4 }} />

        <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'stretch', mb: 4 }}>
          <Box sx={{ 
            width: { xs: '100%', md: '70%' }, 
            pr: { xs: 0, md: 3 }, 
            display: 'flex', 
            flexDirection: 'column', 
            justifyContent: 'center'
          }}>
            <Typography variant="h3" gutterBottom sx={{ fontWeight: 'bold' }}>
              1. Professional Graduate Degrees
            </Typography>
            <Typography variant="body1" paragraph>
              Professional graduate degrees focus on training students for specific professions. They are highly specialized and often lead directly into careers that require advanced qualifications. These programs are designed to provide students with practical skills and knowledge that can be immediately applied in their chosen field. The curriculum often includes case studies, internships, and projects that simulate real-world scenarios, preparing graduates for leadership roles and specialized positions in their industries.
            </Typography>
          </Box>
          <Box sx={{ 
            width: { xs: '100%', md: '30%' }, 
            mb: { xs: 2, md: 0 },
            position: 'relative',
            overflow: 'hidden',
            paddingTop: { xs: '56.25%', md: '0' }, // 16:9 aspect ratio for xs, auto for md
            height: { md: 'auto' },
          }}>
            <img 
              src="/images/blog1-1.png" 
              alt="Professional graduate students in a seminar" 
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                borderRadius: '8px',
              }} 
            />
          </Box>
        </Box>

        <Typography variant="body1" sx={{ mb: 2 }}>Common examples include:</Typography>
        <List sx={{ width: '100%' }}>
          {[
            {
              primary: 'Master in Accounting (MAcc)',
              secondary: 'Provides advanced knowledge in accounting principles and financial reporting. It prepares students for leadership roles in finance or certification as CPAs.'
            },
            {
              primary: 'Master of Human Resources (MHR)',
              secondary: ' Designed for careers in human resources management, focusing on organizational behavior, labor relations, and talent management. Graduates are prepared for leadership roles in HR or consulting.'
            },
            {
              primary: 'Master of Health Administration (MHA)',
              secondary: 'Oriented toward careers in healthcare management, focusing on healthcare policy, hospital administration, and leadership in the health sector. Graduates often work in hospital systems or healthcare organizations.'
            },
            {
              primary: 'Master of Finance (MFin)',
              secondary: 'Focuses on financial analysis, investment management, and corporate finance. Graduates are equipped for roles in finance, such as financial analysts, portfolio managers, or investment bankers.'
            },
          ].map((item, index) => (
            <ListItem key={index}>
              <ListItemText 
                primary={<Typography variant="body1" sx={{ fontWeight: 'bold', fontStyle: 'italic' }}>{item.primary}</Typography>}
                secondary={item.secondary}
              />
            </ListItem>
          ))}
        </List>

        <Typography variant="body1" paragraph>
          Professional degrees typically range from 3 to 6 years in length, depending on the field of study and the level of specialization.        
        </Typography>

        <Divider sx={{ my: 4 }} />

        <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'stretch', mb: 4 }}>
          <Box sx={{ 
            width: { xs: '100%', md: '70%' }, 
            pr: { xs: 0, md: 3 }, 
            display: 'flex', 
            flexDirection: 'column', 
            justifyContent: 'center'
          }}>
            <Typography variant="h3" gutterBottom sx={{ fontWeight: 'bold' }}>
              2. Academic Graduate Degrees
            </Typography>
            <Typography variant="body1" paragraph>
              Academic degrees are primarily research-oriented and are focused on contributing new knowledge to a particular field. These programs often involve both coursework and original research, and some provide stipends to help support students during their studies. Academic graduate degrees are designed for those who wish to delve deeply into a specific area of study, often with the goal of advancing knowledge in that field. These programs emphasize critical thinking, research methodologies, and the development of expertise in a specialized area. Graduates of these programs are well-prepared for careers in academia, research institutions, or roles that require advanced analytical and research skills.
            </Typography>
          </Box>
          <Box sx={{ 
            width: { xs: '100%', md: '30%' }, 
            mb: { xs: 2, md: 0 },
            position: 'relative',
            overflow: 'hidden',
            paddingTop: { xs: '56.25%', md: '0' }, // 16:9 aspect ratio for xs, auto for md
            height: { md: 'auto' },
          }}>
            <img 
              src="/images/blog1-2.png" 
              alt="Graduate students in a library" 
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                borderRadius: '8px',
              }} 
            />
          </Box>
        </Box>

        <Typography variant="h4" gutterBottom sx={{ mt: 4, mb: 2, fontWeight: 'bold' }}>
          Master's Degrees
        </Typography>

        <List sx={{ width: '100%' }}>
          {[
            {
              primary: 'Course-Based Master\'s',
              secondary: 'These programs are structured around coursework, much like undergraduate degrees, but at a more advanced level. Students typically complete these programs in 1-3 years. Course-based master\'s programs are often designed for students who want to enter the workforce or advance in their careers without the research component.'
            },
            {
              primary: 'Thesis-Based Master\'s',
              secondary: 'In contrast, thesis-based master\'s programs involve both coursework and a significant research project culminating in a thesis. These programs generally take around 2 years to complete. While not as extensive as a PhD, they allow students to contribute to academic research in their field of study.'
            }
          ].map((item, index) => (
            <ListItem key={index}>
              <ListItemText 
                primary={<Typography variant="body1" sx={{ fontWeight: 'bold', fontStyle: 'italic' }}>{item.primary}</Typography>}
                secondary={item.secondary}
              />
            </ListItem>
          ))}
        </List>

        <Typography variant="body1" paragraph>
          Common examples include:        
        </Typography>

        <List sx={{ width: '100%' }}>
          {[
            {
              primary: 'Master of Psychology (MA/MSc)',
              secondary: ' Focuses on psychological theories and research methods. Students typically conduct original research leading to a thesis, preparing them for doctoral studies or careers in mental health, education, or research.'
            },
            {
              primary: 'Master of Data Science (MDS)',
              secondary: 'Provides advanced training in data analysis, machine learning, and statistical modeling. Students complete a research thesis or project, equipping them for roles in academia, industry, or further PhD studies.'
            },
            {
              primary: 'Master of Biochemistry (MSc)',
              secondary: 'Centers on biochemical processes and molecular biology, with students conducting lab-based research for their thesis. Graduates often pursue doctoral programs or research positions in biotech and pharmaceuticals.'
            }
          ].map((item, index) => (
            <ListItem key={index}>
              <ListItemText 
                primary={<Typography variant="body1" sx={{ fontWeight: 'bold', fontStyle: 'italic' }}>{item.primary}</Typography>}
                secondary={item.secondary}
              />
            </ListItem>
          ))}
        </List>

        <Typography variant="h4" gutterBottom sx={{ mt: 6, mb: 2, fontWeight: 'bold' }}>
          What is a PhD?
        </Typography>
        <Typography variant="body1" paragraph>
          The PhD is the highest level of academic study and focuses on producing original research. PhD students typically spend 4-7 years working on their dissertation—a significant body of research that makes a new contribution to their field. While coursework is a component of the program, the majority of time is spent on research. PhD graduates often pursue careers in academia, research institutions, or high-level roles in various industries.
        </Typography>

        <SuggestedReads currentPostId={1} blogPosts={blogPosts} />
      </Container>
    </Box>
  );
}

export default WhatAreGraduateStudies;