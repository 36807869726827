import React from 'react';
import { Typography, Card, CardContent, Button, Box, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const SuggestedReads = ({ currentPostId, blogPosts }) => {
  const navigate = useNavigate();
  const currentIndex = blogPosts.findIndex(post => post.id === currentPostId);
  const prevPost = currentIndex > 0 ? blogPosts[currentIndex - 1] : null;
  const nextPost = currentIndex < blogPosts.length - 1 ? blogPosts[currentIndex + 1] : null;

  const handleNavigate = (postId) => {
    navigate(`/blog/${postId}`);
    window.scrollTo(0, 0);
  };

  const BlogCard = ({ post, direction }) => (
    <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%', overflow: 'hidden' }}>
      <Box sx={{ position: 'relative', paddingTop: '56.25%', overflow: 'hidden' }}>
        <img
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
          src={`/${post.image}`}
          alt={post.title}
        />
      </Box>
      <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', p: 2 }}>
        <Typography variant="subtitle1" component="div" fontWeight="bold" gutterBottom>
          {direction === 'prev' ? 'Previous Post' : 'Next Post'}
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 2, flexGrow: 1 }}>
          {post.title}
        </Typography>
        <Button 
          variant="contained"
          size="small"
          startIcon={direction === 'prev' ? <ArrowBackIcon /> : null}
          endIcon={direction === 'next' ? <ArrowForwardIcon /> : null}
          onClick={() => handleNavigate(post.id)}
          sx={{
            mt: 'auto',
            backgroundColor: 'rgba(10, 10, 10, 0.9)',
            color: 'white',
            '&:hover': {
              backgroundColor: 'rgba(10, 10, 10, 1)',
            }
          }}
        >
          {direction === 'prev' ? 'Previous' : 'Next'}
        </Button>
      </CardContent>
    </Card>
  );

  return (
    <Box sx={{ mt: 8 }}>
      <Typography variant="h4" gutterBottom>
        Continue Reading
      </Typography>
      <Grid container spacing={2}>
        {prevPost && (
          <Grid item xs={12} md={6}>
            <BlogCard post={prevPost} direction="prev" />
          </Grid>
        )}
        {nextPost && (
          <Grid item xs={12} md={6}>
            <BlogCard post={nextPost} direction="next" />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default SuggestedReads;